import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08f65c7e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { style: {"display":"flex"} }
const _hoisted_4 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_b_form_input = _resolveComponent("b-form-input")!
  const _component_b_table = _resolveComponent("b-table")!

  return (_openBlock(), _createBlock(_component_b_table, {
    items: _ctx.tableData,
    fields: _ctx.fields,
    class: "cycle-count-table",
    "row-height": "64px"
  }, {
    "cell(newCount)": _withCtx((row) => [
      (!_ctx.disableAdjustments)
        ? (_openBlock(), _createBlock(_component_b_form_input, {
            key: 0,
            modelValue: row.item.newCount,
            "onUpdate:modelValue": ($event: any) => ((row.item.newCount) = $event),
            disabled: true,
            type: "number",
            style: {"width":"100px"},
            "hide-stepper": "",
            onChange: (value) => _ctx.updateAdjustmentQuantity(value, row.item.cycleCountItem)
          }, null, 8, ["modelValue", "onUpdate:modelValue", "onChange"]))
        : _createCommentVNode("", true),
      (_ctx.disableAdjustments)
        ? (_openBlock(), _createElementBlock("span", _hoisted_1, _toDisplayString(row.item.newCount), 1))
        : _createCommentVNode("", true)
    ]),
    "cell(systemCount)": _withCtx((row) => [
      (typeof row.item.systemCount === 'number')
        ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
            _createElementVNode("div", _hoisted_3, [
              _createElementVNode("span", null, _toDisplayString(row.item.systemCount), 1)
            ])
          ]))
        : (_openBlock(), _createElementBlock("span", _hoisted_4, "-"))
    ]),
    "cell(inventoryAdjustment)": _withCtx((row) => [
      _createElementVNode("span", {
        class: _normalizeClass({ bold: row.item.isOutsideOfThreshold })
      }, _toDisplayString(row.item.inventoryAdjustment), 3)
    ]),
    _: 1
  }, 8, ["items", "fields"]))
}