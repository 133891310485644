
import {
    computed, defineComponent, PropType,
} from 'vue';
import CycleCountReport from '@/domain/cyclecount/CycleCountReport';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import BFormInput from '@/components/bootstrap-library/BFormInput.vue';
import { getTitleCaseTranslation } from '@/services/TranslationService';

type TableData = {
    itemId: number;
    itemName: string;
    newCount?: number;
    dateCounted?: string;
    cycleCountItem: CycleCountItem;
    systemCount?: number;
    inventoryAdjustment?: number;
    count?: number;
    underLoadPieces?: number;
};

export default defineComponent({
    name: 'cycle-count-report-approve',
    components: {
        BFormInput,
        BTable,
    },
    props: {
        report: {
            type: Object as PropType<CycleCountReport>,
            required: true,
        },
        disableAdjustments: Boolean,
    },
    setup(props) {
        function mapItemsToTableData(cycleCountItems: Array<CycleCountItem>): Array<TableData> {
            return cycleCountItems.filter((f) => f.item).map((i) => ({
                itemId: i.item?.id ?? i.plannedPart?.id ?? 0,
                itemName: i.item?.name ?? i.plannedPart?.part.description ?? '',
                count: i.count,
                underLoadPieces: i.underLoadPieces,
                newCount: i.underLoadPieces !== undefined && i.count !== undefined ? Math.abs(i.count) + Math.abs(i.underLoadPieces) : i.count,
                dateCounted: i.dateCounted ? i.dateCounted.toDateString() : undefined,
                cycleCountItem: i,
                systemCount: i.systemCount,
                inventoryAdjustment: i.inventoryAdjustment,
            }));
        }

        const tableData = computed((): Array<TableData> => mapItemsToTableData(props.report.cycleCountItems));

        const fields: Array<BTableField<TableData>> = [
            { key: 'itemId', label: getTitleCaseTranslation('core.domain.containerNumber'), width: '140px' },
            { key: 'itemName', label: getTitleCaseTranslation('core.domain.containerName') },
            { key: 'dateCounted', label: getTitleCaseTranslation('core.domain.dateCounted') },
            {
                key: 'count',
                label: getTitleCaseTranslation('core.domain.loosePiecesShort'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'underLoadPieces',
                label: getTitleCaseTranslation('core.domain.underLoadPiecesShort'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'newCount',
                label: getTitleCaseTranslation('core.domain.totalCount'),
                ignoreSort: true,
                searchable: false,
            },
            {
                key: 'systemCount',
                ignoreSort: true,
                searchable: false,
                label: getTitleCaseTranslation('core.domain.systemCount'),
            },
            {
                key: 'inventoryAdjustment',
                ignoreSort: true,
                searchable: false,
                label: getTitleCaseTranslation('core.domain.adjustmentQuantity'),
            },
        ];

        function updateAdjustmentQuantity(value: number | undefined, item: CycleCountItem) {
            if (value !== undefined) {
                item.setInventoryAdjustment(value);
                if (item.systemCount !== undefined) {
                    item.setInventoryAdjustment(Math.abs(value) - Math.abs(item.systemCount));
                }
            }
        }

        return {
            tableData,
            fields,
            updateAdjustmentQuantity,
        };
    },
});
