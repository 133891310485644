
import {
    computed, defineComponent, onBeforeMount, reactive,
} from 'vue';
import CycleCountReportService from '@/services/CycleCountReportService';
import CycleCountReport, { CycleCountReportStatus } from '@/domain/cyclecount/CycleCountReport';
import router from '@/router';
import coreStore from '@/store/CoreStore';
import { ItemType } from '@/domain/enums/ItemType';
import Item from '@/domain/Item';
import Screen from '@/components/layout/Screen.vue';
import BModal from '@/components/bootstrap-library/modal/BModal.vue';
import CycleCountReportApprove from '@/views/cyclecount/CycleCountReportApprove.vue';
import CycleCountReportCount from '@/views/cyclecount/CycleCountReportCount.vue';
import BButton from '@/components/bootstrap-library/BButton.vue';
import ItemPicker from '@/components/ItemPicker.vue';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import BDropdownItem from '@/components/bootstrap-library/BDropdownItem.vue';
import BDropdown from '@/components/bootstrap-library/BDropdown.vue';
import useModuleUtil from '@/composable/useModuleUtil';
import EmptyState from '@/components/EmptyState.vue';
import useStringFormatter from '@/composable/useStringFormatter';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';
import sidebarStore from '@/components/sidebar/store/SidebarStore';
import useScreenSize from '@/composable/useScreenSize';
import { useNotification } from '@/composable/useNotifications';

type State = {
    report: CycleCountReport | null;
    loading: boolean;
    busy: boolean;
    showItemSearch: boolean;
    itemPickerErrorMsg?: string;
};

export default defineComponent({
    name: 'cycle-count-report',
    components: {
        EmptyState,
        BDropdown,
        BDropdownItem,
        BSpinner,
        ItemPicker,
        BButton,
        CycleCountReportCount,
        CycleCountReportApprove,
        BModal,
        Screen,
    },
    props: {
        reportId: {
            type: String,
            required: false,
            default: undefined,
        },
        canApproveCount: {
            type: Boolean,
            default: false,
        },
        canEditCount: {
            type: Boolean,
            default: false,
        },
        dashboard: {
            type: String,
            default: undefined,
        },
    },
    setup(props) {
        const cycleCountReportService = new CycleCountReportService();
        const { userLocation } = coreStore.getInstance().profileStore;
        const sideBarStore = sidebarStore.getInstance();

        const { activeModule } = useModuleUtil();
        const { capitalizeFirstLetter } = useStringFormatter();
        const { isMobile } = useScreenSize();

        const { showErrorList } = useNotification();

        const state = reactive<State>({
            loading: true,
            busy: false,
            showItemSearch: false,
            report: null,
        });

        const screen = computed((): 'count' | 'approve' | 'end' => {
            if (state.report?.status === CycleCountReportStatus.Open) return 'count';

            if (props.canApproveCount) {
                return 'approve';
            }

            return 'end';
        });

        const nullReportMessage = computed((): string => {
            if (props.reportId) return `${capitalizeFirstLetter(getTranslation('core.common.unableToFindCycleCount'))} ${props.reportId}.`;
            return capitalizeFirstLetter(getTranslation('core.common.allCycleCountsComplete'));
        });

        async function getReport() {
            state.loading = true;
            if (props.reportId) {
                state.report = await cycleCountReportService.getCycleCountReportById(parseInt(props.reportId, 10));
            } else {
                state.report = await cycleCountReportService.getOpenCycleCountReportByLocation(userLocation.id);
            }
            state.loading = false;
        }

        onBeforeMount(async () => {
            if (isMobile.value && sideBarStore.sideBarVisible) {
                sideBarStore.toggleSidebar();
            }
            await getReport();
        });

        const title = computed((): string => getTitleCaseTranslation('core.domain.cycleCountReport') + (state.report?.id ? ` : ${state.report?.id}` : ''));

        function goBack() {
            router.back();
        }

        function searchContainer() {
            state.itemPickerErrorMsg = undefined;
            state.showItemSearch = true;
        }

        function hasItemAlreadyBeenCounted(item: Item): boolean {
            const index = state.report ? state.report.cycleCountItems.findIndex((i) => i.item?.id === item.id && i.dateCounted) : -1;
            return index > -1;
        }

        async function onItemSelect(data: { item: Item; quantity: number }) {
            if (hasItemAlreadyBeenCounted(data.item)) {
                state.itemPickerErrorMsg = capitalizeFirstLetter(getTranslation('core.common.itemHasAlreadyBeenCounted'));
                return;
            }
            if (state.report) {
                state.busy = true;
                const response = await cycleCountReportService.submitCycleCountItem({
                    reportId: state.report.id,
                    count: data.quantity,
                    itemId: data.item.id,
                    locationId: state.report.location.id,
                });
                if (response.success) {
                    state.report = new CycleCountReport(response.report);
                } else {
                    // download the report and get the updated item count
                    const reportResponse = await cycleCountReportService.getCycleCountReportById(state.report.id);
                    if (reportResponse) {
                        const items = reportResponse.cycleCountItems.filter((x) => x.item?.id === data.item.id);
                        if (items.length) {
                            state.report.updateItemCount(data.item, items[0].count ?? 0);
                        }
                    }
                }
                state.showItemSearch = false;
                state.busy = false;
            }
        }

        async function closeReport() {
            if (state.report) {
                if (props.canApproveCount) {
                    const response = await cycleCountReportService.closeReport(state.report.id);
                    if (response) {
                        state.report.closeReport();
                    }
                }
            }
        }

        async function closeReportAndAdjustInventory() {
            if (state.report) {
                if (props.canApproveCount) {
                    state.report.cycleCountItems = state.report.cycleCountItems.filter((cci) => cci.item);
                    const response = await cycleCountReportService.closeReportAndAdjustInventory(state.report as CycleCountReport);
                    if (response.success) {
                        state.report.closeReport();
                    } else if (response.errorList) {
                        showErrorList(Object.values(response.errorList));
                    }
                }
            }
        }

        async function finalizeCounts() {
            if (state.report) {
                const updatedReport = await cycleCountReportService.finalizeCounts(state.report.id);

                if (updatedReport) {
                    state.report = updatedReport;
                }
            }
        }

        async function goToCycleCountDashboard() {
            await router.push({ name: props.dashboard });
        }

        async function reopenReport() {
            if (state.report?.id) {
                const response = await cycleCountReportService.reopenReportById(state.report.id);
                if (response) {
                    state.loading = true;
                    state.report = response;
                    state.loading = false;
                }
            }
        }

        return {
            state,
            goBack,
            searchContainer,
            title,
            onItemSelect,
            ItemType,
            CycleCountReportStatus,
            getReport,
            closeReport,
            closeReportAndAdjustInventory,
            screen,
            activeModule,
            goToCycleCountDashboard,
            nullReportMessage,
            getTranslation,
            getTitleCaseTranslation,
            capitalizeFirstLetter,
            finalizeCounts,
            isMobile,
            reopenReport,
        };
    },
});
