
import {
    computed, defineComponent, onBeforeMount, PropType, reactive,
} from 'vue';
import TransactionService from '@/services/TransactionService';
import CycleCountReport from '@/domain/cyclecount/CycleCountReport';
import CycleCountItem from '@/domain/cyclecount/CycleCountItem';
import Transaction from '@/domain/Transaction';
import BTable, { BTableField } from '@/components/bootstrap-library/table/BTable/BTable.vue';
import CycleCountQtyPicker from '@/views/cyclecount/CycleCountQtyPicker.vue';
import CycleCountReportMobileCountItem from '@/views/cyclecount/CycleCountReportMobileCountItem.vue';
import Popover from '@/components/bootstrap-library/Popover.vue';
import { useNotification } from '@/composable/useNotifications';
import CycleCountReportService from '@/services/CycleCountReportService';
import useStringFormatter from '@/composable/useStringFormatter';
import BCol from '@/components/bootstrap-library/BCol.vue';
import BRow from '@/components/bootstrap-library/BRow.vue';
import Thumbnail from '@/components/Thumbnail.vue';
import { getTranslation, getTitleCaseTranslation } from '@/services/TranslationService';

type State = {
    transactions?: Array<Transaction>;
    busy: boolean;
};

type TableData = {
    imageUrlThumb?: string;
    imageUrlFull?: string;
    itemName: string;
    count?: number;
    dateCounted?: string;
    cycleCountItem: CycleCountItem;
    flags?: boolean;
    systemCount?: number;
    underLoadPieces?: number;
    variance?: number;
};

export default defineComponent({
    name: 'cycle-count-report-count',
    components: {
        Thumbnail,
        CycleCountQtyPicker,
        CycleCountReportMobileCountItem,
        BCol,
        BRow,
        BTable,
        Popover,
    },
    props: {
        modelValue: {
            type: Object as PropType<CycleCountReport>,
            required: true,
        },
        isMobile: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const transactionService = new TransactionService();
        const cycleCountReportService = new CycleCountReportService();

        const { capitalizeFirstLetter } = useStringFormatter();

        const state = reactive<State>({
            busy: false,
            transactions: [],
        });

        async function getInboundTransactions() {
            const response = await transactionService.getInboundDeliveryConfirmedTransactions(props.modelValue.location.id);
            if (response.success) {
                state.transactions = response.transactions;
            }
        }

        onBeforeMount(async () => {
            await getInboundTransactions();
        });

        function mapItemsToTableData(cycleCountItems: Array<CycleCountItem>): Array<TableData> {
            return cycleCountItems.map((i) => ({
                imageUrlThumb: i.item?.imageUrlThumb ?? '',
                imageUrlFull: i.item?.imageUrlFull ?? '',
                itemName: i.item?.name ?? i.plannedPart?.part.descriptiveDisplayNumber ?? '',
                count: i.count,
                dateCounted: i.dateCounted ? i.dateCounted.toDateString() : undefined,
                underLoadPieces: i.underLoadPieces,
                cycleCountItem: i,
            }));
        }

        const tableData = computed((): Array<TableData> => mapItemsToTableData(props.modelValue.cycleCountItems));

        function shouldItemBeFlagged(item: TableData): boolean {
            return (item.cycleCountItem.item && state.transactions?.some((x) => x.getTransactionLineByItem(item.cycleCountItem.item!))) ?? false;
        }

        function getTransactionsForItem(item: TableData): Array<Transaction> {
            if (state.transactions && item.cycleCountItem.item) {
                return state.transactions.filter((x) => x.getTransactionLineByItem(item.cycleCountItem.item!)).map((x) => x as Transaction);
            }
            return [];
        }

        const fields: Array<BTableField<TableData>> = [
            {
                key: 'imageUrlThumb',
                label: getTitleCaseTranslation('core.domain.image'),
                width: '70px',
                ignoreSort: true,
            },
            { key: 'itemName', label: getTitleCaseTranslation('core.domain.containerName'), width: '25%' },
            { key: 'dateCounted', label: getTitleCaseTranslation('core.domain.dateCounted'), width: '15%' },
            {
                key: 'flags',
                label: ' ',
                ignoreSort: true,
                searchable: false,
                width: '50px',
            },
            {
                key: 'count',
                label: getTitleCaseTranslation('core.domain.count'),
                ignoreSort: true,
                searchable: false,
            },
        ];

        function isBusy(): boolean {
            if (state.busy) {
                useNotification().showError(capitalizeFirstLetter(getTranslation('core.common.waitForPreviousCountToFinishSaving')));
                return true;
            }

            return false;
        }

        function updateCycleCount(cycleCountItem: CycleCountItem, newCount?: number) {
            if (cycleCountItem.item) {
                props.modelValue.updateItemCount(cycleCountItem.item, newCount);
            } else if (cycleCountItem.plannedPart) {
                props.modelValue.updateItemCount(cycleCountItem.plannedPart, newCount);
            }
        }

        async function resetCount(cycleCountItem: CycleCountItem) {
            if (isBusy()) {
                return;
            }

            state.busy = true;
            const response = await cycleCountReportService.resetCycleCountItem({
                locationId: props.modelValue.location.id,
                reportId: props.modelValue.id,
                itemId: cycleCountItem.item?.id,
                plannedPartId: cycleCountItem.plannedPart?.id,
            });

            if (response.success) {
                emit('update:modelValue', new CycleCountReport(response.data));
            }
            state.busy = false;
        }

        async function submitCount(data: CycleCountItem, count: number | undefined): Promise<boolean> {
            if (isBusy()) {
                return false;
            }

            if (count !== undefined) {
                state.busy = true;
                const response = await cycleCountReportService.submitCycleCountItem({
                    reportId: props.modelValue.id,
                    count,
                    itemId: data.item?.id,
                    plannedPartId: data.plannedPart?.id,
                    locationId: props.modelValue.location.id,
                });

                if (response.success) {
                    emit('update:modelValue', new CycleCountReport(response.report));
                } else {
                    // download the report and get the updated item count
                    const getCycleCountReportResp = await cycleCountReportService.getCycleCountReportById(props.modelValue.id);
                    if (getCycleCountReportResp) {
                        getCycleCountReportResp.cycleCountItems.forEach((x) => {
                            if (typeof x.count === 'number') {
                                updateCycleCount(x, x.count);
                            }
                        });
                    }
                }

                state.transactions = response.transactions;
                state.busy = false;
                return true;
            }
            return true;
        }

        return {
            state,
            submitCount,
            tableData,
            fields,
            shouldItemBeFlagged,
            getTransactionsForItem,
            capitalizeFirstLetter,
            getTranslation,
            getTitleCaseTranslation,
            resetCount,
        };
    },
});
